'use client';
import { useUnit } from 'effector-react';
import { useEffect } from 'react';
import { isServer } from '@/shared/app/isServer';
import { createEvent, createStore, sample } from 'effector';
export type TScreen = {
    xs: '810px';

    sm: '1024px';

    md: '1240px';

    lg: '1366px';

    xl: '1600px';

    '2xl': '1920px';
};

export enum EScreenSize {
    xs = 'xs',
    sm = 'sm',
    md = 'md',
    lg = 'lg',
}

export const screenSizes = {
    xs: '810px',
    sm: '1024px',
    md: '1240px',
    lg: '1366px',
    xl: '1600px',
    '2xl': '1920px',
};

const xsScreenMediaQuery = `(max-width: ${screenSizes.xs})`;
const smScreenMediaQuery = `(min-width: ${screenSizes.xs}) and (max-width: ${screenSizes.sm})`;
const mdScreenMediaQuery = `(min-width: ${screenSizes.sm}) and (max-width: ${screenSizes.md})`;
const lgScreenMediaQuery = `(min-width: ${screenSizes.md})`;
export const $screenSize = createStore<keyof TScreen | null>(null, { name: 'screenSize' });
export const screenSizeUpdated = createEvent<keyof TScreen | null>();

sample({
    clock: screenSizeUpdated,
    target: $screenSize,
});

export const useScreenSize = (): keyof TScreen | null => {
    const [screenSize, setScreenSize] = useUnit([$screenSize, screenSizeUpdated]);
    useEffect(() => {
        if (isServer) {
            return;
        }

        const xsMql = window.matchMedia(xsScreenMediaQuery);
        const smMql = window.matchMedia(smScreenMediaQuery);
        const mediumMql = window.matchMedia(mdScreenMediaQuery);
        const largeMql = window.matchMedia(lgScreenMediaQuery);

        //init
        if (xsMql.matches) {
            setScreenSize(EScreenSize.xs);
        } else if (smMql.matches) {
            setScreenSize(EScreenSize.sm);
        } else if (mediumMql.matches) {
            setScreenSize(EScreenSize.md);
        } else if (largeMql.matches) {
            setScreenSize(EScreenSize.lg);
        }
        const xsMqlListener = (e: MediaQueryListEvent) => {
            if (e.matches) {
                setScreenSize(EScreenSize.xs);
            }
        };
        const smMqlListener = (e: MediaQueryListEvent) => {
            if (e.matches) {
                setScreenSize(EScreenSize.sm);
            }
        };
        const mediumMqlListener = (e: MediaQueryListEvent) => {
            if (e.matches) {
                setScreenSize(EScreenSize.md);
            }
        };
        const largeMqlListener = (e: MediaQueryListEvent) => {
            if (e.matches) {
                setScreenSize(EScreenSize.lg);
            }
        };

        //resize
        xsMql.addEventListener('change', xsMqlListener);
        smMql.addEventListener('change', smMqlListener);
        mediumMql.addEventListener('change', mediumMqlListener);
        largeMql.addEventListener('change', largeMqlListener);
        return () => {
            xsMql.removeEventListener('change', xsMqlListener);
            smMql.removeEventListener('change', smMqlListener);
            mediumMql.removeEventListener('change', mediumMqlListener);
            largeMql.removeEventListener('change', largeMqlListener);
        };
    }, [setScreenSize]);

    return screenSize;
};
